<script setup lang="ts">
import { mdiCog } from "@mdi/js";

// composables
const { t, locale: currentLocale, locales, setLocale } = useI18n();

const locale = computed({
  get: () => currentLocale.value,
  set: (value) => setLocale(value),
});

const { isListItem } = defineProps<{
  isListItem?: boolean;
}>();
</script>

<template>
  <MsSettingDialog
    v-model="locale"
    :locales="(locales as LocaleObject[])"
  >
    <template
      v-if="isListItem"
      #activator="{ props }"
    >
      <MListItem
        v-bind="props"
        :title="t('app-settings')"
        :prependIcon="mdiCog"
      />
    </template>
  </MsSettingDialog>
</template>
